<template>
  <div class="accounts__page" v-if="data">
    <Redirect class="" title="ACCOUNT" route="website.settings.account" />
    <v-row no-gutters>
      <v-col class="page-header" cols="12">
        <h2 class="page-title">{{ 'Your profile' }}</h2>
      </v-col>
    </v-row>
    <v-divider></v-divider>
    <v-row no-gutters class="py-4">
      <v-col cols="12" sm="4" class="">
        <UploadOneImage :hiddenDelete="true" :image="{ src: data.profilePicture }" @callback="getImageUpLoad" />

        <!-- <images-uploader
          minWidth="250"
          minHeight="250"
          :hiddenDelete="true"
          :images="[
            {
              src: data.profilePicture,
            },
          ]"
          @callback="getImageUpLoad"
        /> -->
      </v-col>
      <v-col cols="12" sm="8" class="flex-column pa-3 elevation-form flex-column">
        <v-row gutters>
          <v-col cols="12">
            <Notification v-if="errors && errors.length > 0" :messages="errors" type="error" />
          </v-col>
          <v-col cols="12" sm="6">
            <InputDefault :model="['data', 'firstName']" fieldName="First name" label="First name" :isValidate="true" />
          </v-col>
          <v-col cols="12" sm="6">
            <InputDefault :model="['data', 'lastName']" fieldName="Last name" label="Last Name" :isValidate="true" />
          </v-col>
          <v-col cols="12" sm="6">
            <InputDefault :model="['data', 'email']" fieldName="Email" label="Email" :isValidate="true" />
          </v-col>
          <v-col cols="12" sm="6">
            <InputDefault :model="['data', 'phone']" fieldName="Phone" label="Phone" :isValidate="true" />
          </v-col>
        </v-row>
        <div class="d-flex mt-3"></div>
        <h3>Notifications</h3>
        <v-checkbox hide-details="" label="Allow important notifications to be sent by email"> </v-checkbox>
        <div class="ml-5">
          <small class="input-description"
            >We periodically send out important news about Lattehub to our users via email. We keep the email volume to
            an absolute minimum.</small
          >
        </div>
        <v-col cols="12" class="my-5">
          <v-divider></v-divider>
        </v-col>
        <div class="d-flex mt-3"></div>
        <h3>Password reset</h3>
        <small> Change the password you use to login to {{ domain }} </small>
        <v-col v-if="!showFormChangePass" cols="12" sm="6">
          <v-btn class="mt-2" small outlined @click="showFormChangePass = true">Change Password</v-btn>
        </v-col>
        <v-row v-else>
          <v-col cols="12" sm="6">
            <small class="input-description fz-14">New Password</small>
            <v-text-field :type="'password'" v-model="formChangePass.newPassword" fieldName="New Password" />
          </v-col>
          <v-col cols="12" sm="6">
            <small class="input-description fz-14">Confirm password</small>

            <v-text-field
              :type="'password'"
              :error-messages="errorNoSame"
              v-model="formChangePass.confirmPassword"
              fieldName="Confirm password"
            />
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" class="my-5">
        <v-divider></v-divider>
      </v-col>

      <v-row no-gutters class="py-4">
        <v-col cols="12" sm="4" class="">
          <h3>Recent login history</h3>
          <p>
            Access from an ISP, Location, or IP address you don't recognize may indicate that the account has been
            compromised and should be reviewed further.
          </p>
        </v-col>
        <v-col cols="12" sm="8" class="flex-column pa-3 elevation-form flex-column">
          <table class="h-table">
            <thead>
              <tr>
                <th>
                  <p>Date</p>
                </th>
                <th>
                  <p>IP</p>
                </th>
                <!-- <th>
                  <p>ISP</p>
                </th> -->
                <th>
                  <p>Location</p>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="i in historyLogin" :key="i.createdAt">
                <td>
                  <span>
                    {{ i.createdAt }}
                  </span>
                </td>
                <td>
                  <span>
                    {{ i.ip }}
                  </span>
                </td>
                <td>
                  <span>
                    {{ 'Viet Nam' }}
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
          <v-btn
            :loading="loadingLogout"
            v-if="isStoreOwner"
            color=""
            class="mr-3 mt-5"
            @click="showModalLogout = true"
          >
            Log out</v-btn
          >
        </v-col>
      </v-row>
      <v-col cols="12" class="my-5">
        <v-divider></v-divider>
      </v-col>

      <v-col v-if="isStoreOwner" cols="12" class="d-flex">
        <v-spacer></v-spacer>
        <v-btn outlined class="mr-3" @click="onCancel">Cancel</v-btn>
        <v-btn color="primary" @click="checkBeforeSubmit">Save</v-btn>
      </v-col>
    </v-row>
    <modal model="showModalConfirmPassword" :title="`Please confirm your password`" :callback="submitConfirmPassword">
      <template slot="content">
        <v-col cols="12" sm="12">
          <small class="input-description fz-14"> Enter your current password to continue.? </small>
          <v-text-field
            :type="'password'"
            :error-messages="errorPass"
            v-model="confirmPassword"
            fieldName="Confirm Password"
            l
          />
        </v-col>
      </template>
    </modal>
    <modal model="showModalLogout" :title="`Log out ${data.email}`" :callback="submitLogout">
      <template slot="content">
        <small class="input-description"> Are you sure you want to log out? </small>
      </template>
    </modal>
  </div>
</template>
<script>
import Redirect from '@/components/RedirectTo';
import { mapState, mapGetters, mapMutations } from 'vuex';
import { required, minLength, maxLength, email, sameAs } from 'vuelidate/lib/validators';
import { authService } from '@/apis/permission';
import ImagesUploader from '@/components/form/ImagesUploader';
import imageHelpers from '@/helpers/image';
import { imageFake } from '@/const/image';

export default {
  components: {
    Redirect,
    // ImagesUploader,
  },
  data() {
    return {
      domain: window.localStorage.getItem('r_domain'),
      imageHelpers,
      formChangePass: {
        confirmPassword: '',
        newPassword: '',
      },
      showModalConfirmPassword: false,
      showModalLogout: false,
      showFormChangePass: false,
      loadingLogout: false,
      idAccount: null,
      email: '',
      errors: [],
      permissions: [],
      isStoreAdmin: true,
      isStoreOwner: true,
      listPermissions: [],
      isLoading: false,
      historyLogin: [],
      confirmPassword: '',
      errorPass: [],
      errorNoSame: [],
      data: {
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        profilePicture: '',
      },
    };
  },
  validations: {
    // formChangePass: {
    //   confirmPassword: { sameAsPassword: sameAs('newPassword') },
    // },
    data: {
      firstName: { required },
      email: { required, email },
      phone: { required },
      lastName: { required },
    },
  },
  computed: {
    ...mapGetters(['user']),
  },
  methods: {
    getImageUpLoad(images) {
      this.data.profilePicture = images && images.src ? images.src : images;
      this.$forceUpdate();
    },
    ...mapMutations(['setUser']),
    onCancel() {
      this.$router.push({ name: 'website.settings.account' });
    },
    async submitLogout() {
      this.showModalLogout = false;
      this.loadingLogout = true;
      setTimeout(() => {
        localStorage.clear();
        location.reload();
      }, 500);
    },
    async submitConfirmPassword() {
      try {
        if (this.confirmPassword) {
          if (this.showFormChangePass) {
            let res = await authService.changePasswordUser({
              ...this.formChangePass,
              currentPassword: this.confirmPassword,
            });
          }
          await this.onSubmit({
            email: this.data.email,
            confirmPassword: this.confirmPassword,
            firstName: this.data.firstName,
            lastName: this.data.lastName,
            phone: this.data.phone,
            profilePicture: this.data.profilePicture,
          });
          this.errorPass = [];
        } else {
          this.errorPass = ['Current pass required'];
        }
      } catch (error) {
        console.log('error', error, error.response);
      }
    },
    checkBeforeSubmit() {
      if (this.user.email !== this.data.email || this.showFormChangePass) {
        if (this.showFormChangePass) {
          if (
            this.formChangePass.newPassword !== this.formChangePass.confirmPassword ||
            (this.formChangePass.newPassword === '' && this.formChangePass.newPassword === '')
          ) {
            if (this.formChangePass.newPassword === '' && this.formChangePass.newPassword === '') {
              this.errorNoSame = 'Not empty';
            } else {
              this.errorNoSame = 'Confirm password doesnt match ';
            }
          } else {
            this.errorNoSame = '';
            this.confirmPassword = '';
            this.errorPass = [];
            this.showModalConfirmPassword = true;
          }
        } else {
          this.confirmPassword = '';
          this.errorPass = [];
          this.showModalConfirmPassword = true;
        }
      }
      if (this.user.email === this.data.email && !this.showFormChangePass) {
        this.onSubmit({
          profilePicture: this.data.profilePicture
            ? this.data.profilePicture
            : 'https://st3.depositphotos.com/13159112/17145/v/600/depositphotos_171453724-stock-illustration-default-avatar-profile-icon-grey.jpg',
          firstName: this.data.firstName,
          lastName: this.data.lastName,
          phone: this.data.phone,
        });
      }
    },
    async onSubmit(payload) {
      if (!this.$v.$invalid) {
        this.errors = [];

        try {
          let res = await authService.updateUserProfile(payload);

          this.setUser({
            ...res.data,
            isStoreOwner: this.user.isStoreOwner,
            isStoreAdmin: this.user.isStoreAdmin,
            scopes: this.user.scopes,
          });
          localStorage.removeItem('user');
          localStorage.setItem('user', JSON.stringify(res.data));
        } catch (error) {
          this.errors = error;
        }
        this.showModalConfirmPassword = false;
      }
    },
    async checkOwnerStore() {
      try {
        let res = await authService.storePermission();
        this.isStoreOwner = res.data.isStoreOwner;
      } catch (error) {
        console.log(error);
      }
    },
  },
  async mounted() {
    this.data = JSON.parse(localStorage.getItem('user'));
    if (!this.data.profilePicture || this.data.profilePicture === '') {
      this.data.profilePicture = this.imageFake.user;
    }
    if (this.data && this.data.id) {
      try {
        this.idAccount = this.data.id;
        let history = await authService.apiHistoryAccountById(this.idAccount);
        this.historyLogin = history.data;
      } catch (error) {
        console.log(error);
      }
    }
  },
};
</script>
<style lang="scss" scoped>
.h-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
}

.h-table td,
.h-table th {
  border-bottom: 1px solid #ddd;
  padding: 12px;
}

.h-table th {
  padding-top: 2px;
  padding-bottom: 2px;
  text-align: left;
  color: black;
  // background-color: #dddddd;
  // color: white;
  p {
    margin-bottom: 0px !important;
  }
}
</style>
